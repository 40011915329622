import styled from "styled-components";
import {theme} from "../../utils/theme";
import {devices} from "../../utils/devices";

export default styled.a`
  border-radius: .5rem;
  color: ${props => props.color ? props.color : '#FFFFFF'};
  padding: ${props => props.padding ? props.padding : '1rem 1.8rem 1rem 4rem'};
  font-weight: bold;
  font-size: ${props => props.fontSize ? theme.fontSize.text[props.fontSize] : theme.fontSize.text.normal};
  text-decoration: none;
  display: ${props => props.display ? props.display : 'block'};
  background-image: url(${props => props.bgIcon ? theme.iconImage[props.bgIcon] : ''});
  background-size: 1.9rem;
  ${props => props.bgIcon && 'background-repeat: no-repeat;' + 'background-position:left 1rem center;'}
  text-align: left;
  background-color: ${props => props.bg && props.bg};
  border-color: ${props => props.border && props.border};
  border: ${props => props.border && '2.5px solid'};


  &:hover {
    ${props => props.bgIconHover && `background-image: url(${theme.iconImage[props.bgIconHover]});`}
    color: ${props => props.colorHover ? props.colorHover : '#000000'};
    background-color: ${props => props.bgHover ? props.bgHover : '#FFFFFF'};
    border-color: ${props => props.borderHover ? theme.colors[props.borderHover] : '#FFFFFF'};
    border: ${props => props.borderHover && '3.5px solid'};
    transition: all .4s ease-out;
  }

  @media ${devices.mobile} {
    margin: ${props => props.marginMobile && props.marginMobile};
  }

  @media ${devices.laptop} {
    margin: ${props => props.marginDesktop && props.marginDesktop};
  }

`