import styled from "styled-components";
import {theme} from "../../utils/theme";
import {devices} from "../../utils/devices";

export default styled.button`
  width: ${props => props.width && props.width};
  max-width: ${props => props.maxWidth && props.maxWidth};
  margin: ${props => (props.width || props.maxWidth) && "auto"};
  border-radius: .5rem;
  border: 2px solid;
  border-width: ${props => props.borderWidth ? "4px" : ""};
  border-color:${props => props.borderColor ? theme.colors[props.borderColor] : props => props.bg ? theme.colors[props.bg] : '#FFFFFF'};
  background-color:${props => props.bg ? (theme.colors[props.bg] ? theme.colors[props.bg] : props.bg) : '#FFFFFF'};
  color:${props => props.color ? (theme.colors[props.color] ? theme.colors[props.color] : props.color) : '#FFFFFF'};
  
  padding: ${props => props.padding ? props.padding : "1rem 3rem;"};
  font-weight:bold;
  font-size:${props => props.fontSize ? (theme.fontSize.text[props.fontSize] ? theme.fontSize.text[props.fontSize] : props.fontSize) : theme.fontSize.text.normal};
  margin-right: ${props => props.mr && props.mr};
  margin-bottom: ${props => props.mb && props.mb};

  color: ${props => props.color ? props.color : '#FFFFFF'};
  font-size: ${props => props.fontSize ? theme.fontSize.text[props.fontSize] : theme.fontSize.text.normal};
  text-decoration: none;
  display: ${props => props.display ? props.display : 'block'};

  background-image: url(${props => props.bgIcon ? theme.iconImage[props.bgIcon] : ''});
  background-size: ${props => props.bImageSize ? props.bImageSize : '1.9rem'};
  ${props => props.bgIcon && 'background-repeat: no-repeat;' + 'background-position:left 1rem center;'}
  text-align: left;
  
  &:hover {
    ${props => props.bgIconHover && `background-image: url(${theme.iconImage[props.bgIconHover]});`}
    
    color:${props => props.colorHover ? (theme.colors[props.colorHover] ? theme.colors[props.colorHover] : props.colorHover) : '#000000'};
    background-color:${props => props.bgHover ? (theme.colors[props.bgHover] ? theme.colors[props.bgHover] : props.bgHover) : '#FFFFFF'};
    border-color:${props => props.borderHover ? (theme.colors[props.borderHover] ? theme.colors[props.borderHover] : props.borderHover) : '#000000'};
  }
  
  @media ${devices.mobile} {
    margin:${props => props.marginMobile && props.marginMobile};
  }

  @media ${devices.laptop} {
    margin:${props => props.marginDesktop && props.marginDesktop};
  }
  
`