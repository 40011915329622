import styled from "styled-components";
import {devices} from "../../utils/devices";

export default styled.div`
  flex-wrap: wrap;
  
  @media ${devices.mobile} {
    width:100%;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    display:flex;
    padding:0 15px;
    margin-top:${props => props.mtMobile && props.mtMobile};
  }
  
  @media ${devices.laptop} {
    width:100%;
    max-width:1440px;
    margin-top:${props => props.mtDesktop && props.mtDesktop};
  }
`