import styled from "styled-components";
import {devices, sizes} from "../../utils/devices";
import {theme} from "../../utils/theme";

export default styled.div.attrs(props=>({
    className:props.classProp
}))`
  justify-content: ${props => props.justifyContent && props.justifyContent};
  align-items: ${props => props.alignItems && props.alignItems};
  border-radius: ${props => props.borderRadius && props.borderRadius};
  text-align: ${props => props.textAlign && props.textAlign};
  background-color:${props => props.bg ? (theme.colors[props.bg] ? theme.colors[props.bg] : props.bg) : ''};
  background:${props => props.bgContent && props.bgContent};
  position:${props => props.position && props.position};
  overflow-y:${props => props.overflowY && props.overflowY};
  padding:${props => props.padding && props.padding};
  flex-wrap:${props => props.flexWrap && props.flexWrap};
  border-bottom: ${props => props.borderBottom && props.borderBottom};
  flex-direction: ${props => props.flexDir && props.flexDir};
  cursor: ${props => props.cursor && props.cursor};
  width: ${props => props.width && props.width};
  max-width: ${props => props.maxWidth && props.maxWidth};
  right: ${props => props.right && props.right};
  top: ${props => props.top && props.top};
  gap: ${props => props.gap && props.gap};
  ${props => props.notifHover && `
      &:hover {
        background-color: #eaeaea;
        border-radius: 1rem;
        // box-shadow:  2px 2px 2px #cacaca,
        //   -20px -20px 60px #ffffff;
      }
  `}
  
  ${props => props.overflowY &&
    '::-webkit-scrollbar {\n' +
    '    display: none;\n' +
    '}' +
    '    -ms-overflow-style: none;\n' +
    '    scrollbar-width: none;'
};

  ${props => props.offset_tablet_1 && `
      @media (min-width: ${sizes.tablet}) {
        margin-left: 8.33333%;
      }
  `};

  @media (max-width: ${sizes.tablet}) {
    justify-content: ${props => props.justifyContentMobile && props.justifyContentMobile};
  };

  @media ${devices.mobile} {
    display: ${props => props.displayMobile && props.displayMobile};
    // flex-direction: ${props => props.flexDirMobile && props.flexDirMobile};
    position:${props => props.mobilePosition && props.mobilePosition};
    height:${props => props.mobileHeight && props.mobileHeight};
    z-index:${props => props.zIndexMobile && props.zIndexMobile};
    max-height:${props => props.maxHeightMobile && props.maxHeightMobile};
    min-height:${props => props.minHeightMobile && props.minHeightMobile};
    overflow-y: ${props => props.overflowYMobile && props.overflowYMobile};
    overflow-x: ${props => props.overflowXMobile && props.overflowXMobile};
    bottom:${props => props.bottomMobile && props.bottomMobile};
    margin:${props => props.marginMobile && props.marginMobile};
  }

  @media ${devices.laptop} {
    display: ${props => props.displayDesktop && props.displayDesktop};
    flex-direction: ${props => props.flexDir && props.flexDir};
    position:${props => props.desktopPosition && props.desktopPosition};
    height:${props => props.desktopHeight && props.desktopHeight};
    z-index:${props => props.zIndexDesktop && props.zIndexDesktop};
    max-height:${props => props.maxHeightDesktop && props.maxHeightDesktop};
    min-height:${props => props.minHeightDesktop && props.minHeightDesktop};
    bottom:${props => props.bottomDesktop && props.bottomDesktop};
    padding:${props => props.paddingDesktop && props.paddingDesktop};
    margin:${props => props.marginDesktop && props.marginDesktop};

  }

  @media ${devices.maxLaptop} {
    flex-direction: ${props => props.flexDirMobile && props.flexDirMobile};
    margin-block-end: ${props => props.flexDirMobile && "2rem"};
    justify-content: ${props => props.justifyContentMobile && props.justifyContentMobile};
    width: ${props => props.widthMobile && props.widthMobile};

  }
`
