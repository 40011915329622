import Head from "next/head"
import React, {useState} from "react"
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import {devices} from "../utils/devices";
import DivProps from "./items/DivProps";

export default function Layout({
                                   children,
                                   title = "To Drink List",
                                   description = 'L\'outil de référence pour trouver les meilleurs baux by Pulp Connection',
                                   isAuth = true
                                   // ogImage = 'https://hideal.fr/images/global/hideal-RS.png',
                               }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (<>
            <Head>
                <title>{title}</title>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                <meta name="description" content={`${description}`}/>
                <meta property="og:title" content={`${title}`}/>
                {/*<meta property="og:image" content={`${ogImage}`} />*/}
                <meta property="og:description" content={`${description}`}/>
                <link rel="icon" href="/images/favicon.png"/>
            </Head>
            {isAuth ? <Page>
                <Burger src={isMenuOpen ? "/images/menu/cross-burger.svg" : "/images/menu/burger.svg"}
                        alt={"Bo'Spot"} onClick={() => setIsMenuOpen(old => !old)}/>
                <Header isMenuOpen={isMenuOpen}/>
                <Main isNotFullPage>
                    {children}
                </Main>
            </Page> : <Main>
                {children}
            </Main>}
            {/*{isAuth &&*/}
            {/*    <Footer></Footer>*/}
            {/*}*/}
        </>)
}

const Main = styled.main`
  position: relative;
  min-height: 100vh;


  @media (min-width: 1025px) {
    margin-left: ${props => props.isNotFullPage && "23%"};
  }
`
const Page = styled.div`
  //display: flex;
  min-height: 100vh;
`

const Burger = styled.img`
  position: absolute;
  top: 1.15rem;
  right: 1.4rem;
  z-index: 999;

  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

    //@media ${devices.minLaptop} {
  @media (min-width: 1025px) {
    display: none;
  }
`
