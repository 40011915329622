import React from "react"
import DivProps from "./items/DivProps";
import styled from "styled-components";
import {devices} from "../utils/devices";
import ContainerGlobal from "./items/ContainerGlobal";
import Container from "./items/Container";
import LinkDiv from "./items/LinkDiv";
import Script from "next/script";
// import Link from "next/link"

export default function Footer() {
    return (
        <>
            <FooterStyle>
                <Container justifyContent={"flex-start"}>
                    <DivProps classProp={"col12 colsm3"} displayDesktop={"flex"} displayMobile={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <a href={"/"}><Logo src={"/images/logo-little.svg"} alt={"To Drink List"}/></a>
                    </DivProps>
                    <DivProps classProp={"col12 colsm3"} displayDesktop={"flex"} displayMobile={"flex"} justifyContent={"center"} alignItems={"center"} >
                        <div>
                            <Title>Contacter le support</Title>
                            <Information>Mail : support@bospot.fr</Information>
                            <Information>Tél : 01 02 03 04 05</Information>
                        </div>
                    </DivProps>
                    <DivProps classProp={"col12 colsm3"} displayDesktop={"flex"} displayMobile={"flex"} justifyContent={"center"} alignItems={"center"} >
                        <div>
                            <Title>Nous contacter</Title>
                            <Information>Mail : contact@bospot.fr</Information>
                            <Information>Tél : 01 02 03 04 05</Information>
                        </div>
                    </DivProps>
                </Container>
            </FooterStyle>
            <DivProps displayDesktop={"flex"} displayMobile={"flex"} justifyContent={"center"} alignItems={"center"} bg={"secondary"}>
                <LinkDiv href={"/mentions-legales"} color={"#FFFFFF"} bg={"secondary"} bgHover={"secondary"} colorHover={"#b2b2b2"} borderHover={"#000000"}>Mentions légales</LinkDiv>
            </DivProps>
            {/*<script*/}
            {/*    type="text/javascript"*/}
            {/*    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAp1f9Q6Yh7KmxTY2htfPlShPxMBL74x7g&libraries=places"*/}
            {/*/>*/}
            {/*<Script*/}
            {/*    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAp1f9Q6Yh7KmxTY2htfPlShPxMBL74x7g&libraries=places"*/}
            {/*/>*/}
        </>
    )
}

const FooterStyle = styled.footer`
  background-color:#F9F9F9;
  width: 100%;
  display: flex;
  justify-content: center;
  padding:2rem 0;
`

const Logo = styled.img`
  width:5rem;

  @media ${devices.laptop} {
    width:7rem;
  }
`

const Title = styled.h2`
  font-weight:bold;
  text-transform: uppercase;
  font-size:1rem;
  letter-spacing: .05rem;
  margin-bottom:1.5rem;
  margin-top:1.5rem;
  text-align: center;

  @media ${devices.laptop} {
    margin-top:0;
    text-align: left;
  }
`

const Information = styled.p`
  color:#555555;
  font-size:.9rem;
  line-height:50%;
  letter-spacing: .05rem;
  text-align: center;

  @media ${devices.laptop} {
    text-align: left;
  }
`