import React, {useEffect, useState} from "react"
import styled from "styled-components";
import {devices} from "../utils/devices";
import Container from "./items/Container";
import DivProps from "./items/DivProps";
import ContainerGlobal from "./items/ContainerGlobal";
import LinkDiv from "./items/LinkDiv";
// import {slide as Menu} from "react-burger-menu";
import {push as Menu} from "react-burger-menu";
import {theme} from "../utils/theme";
import Submit from "./items/Submit";
import {signOut, useSession} from "next-auth/react";

export default function Header({isMenuOpen}) {

    return (<Heading isMenuOpen={isMenuOpen}>
        <nav>
            <div>
                <img src={"/images/logo.svg"}/>

                <div>
                    <LinkDiv href="/mon-compte" bgIcon={"user"} bgIconHover={"userMagenta"} color={"#FFFFFF"}
                             colorHover={theme.colors.primary} bgHover={"#FFFFFF"}>Mon compte</LinkDiv>
                    <LinkDiv href="/ajouter-un-produit" bgIcon={"add"} bgIconHover={"addMagenta"} color={"#FFFFFF"}
                             colorHover={theme.colors.primary} bgHover={"#FFFFFF"}>Ajouter un produit</LinkDiv>
                    <LinkDiv href="/" bgIcon={"list"} bgIconHover={"listMagenta"} color={"#FFFFFF"}
                             colorHover={theme.colors.primary}
                             bgHover={"#FFFFFF"}>Mes produits</LinkDiv>
                </div>
            </div>
            <LogoutDiv>
                <Submit onClick={() => signOut()} bgIcon={"exitMagenta"} bgIconHover={"exit"} bg={"#FFFFFF"}
                        borderHover={"#FFFFFF"} fontSize={"1.1rem"}
                        color={"primary"} colorHover={"#FFFFFF"} bgHover={theme.colors.primary} bImageSize={"1.6rem"}
                        padding={".9rem 1.8rem .9rem 3.8rem"}>Déconnexion</Submit>
            </LogoutDiv>
        </nav>
    </Heading>)
}


const Heading = styled.header`
  display: flex;
  @media ${devices.maxLaptop} {
    display: ${props => !props.isMenuOpen && 'none'};
  }

  position: fixed;
  z-index: 100;
  height: 100%;
  background-color: ${theme.colors.primary};
  width: 100%;
  //display: flex;
  justify-content: center;
  transition: all 0.5s ease 0s;

  @media (min-width: 1025px) {
    width: 23% !important;
  }

  nav {
    position: relative;
    color: #b8b7ad;
    align-items: center;
    padding: 5rem 0;
    gap: 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      img {
        margin-top: -1rem;
        margin-bottom: 3.5rem;
        width: 162px;
      }
      
      & > div {
        display: flex;
        flex-direction: column;
        gap: .7rem;

        @media ${devices.maxTablet} {
          a {
            font-size: 1.5rem !important;
          }
        }
      }
    }
  }
`

const LogoutDiv = styled.div`
  //position: fixed;
  //bottom: 5rem;
  
`